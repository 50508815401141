<template>
  <form class="d-flex flex-column gap-4" @submit.prevent="onSubmit">
    <!-- SERVER ERROR -->
    <div v-if="serverError != null" class="visitor-error-scrollable-container">
      <VisitorErrorHandler
        v-if="serverError != null"
        keep-alive
        whitespace
        :error="serverError"
        @done="serverError = null"
      />
    </div>
    <!-- SERVER ERROR -->

    <!-- SELECT CHOICE -->
    <div class="d-flex gap-4">
      <CustomCheck
        name="select-adults"
        label="Select adults"
        :model-value="form.selectAdult"
        @change="
          (value) => {
            form.selectAdult = value
          }
        "
      />
      <CustomCheck
        name="upload-file"
        label="Upload file"
        :model-value="!form.selectAdult"
        @change="
          (value) => {
            form.selectAdult = !value
          }
        "
      />
    </div>
    <!-- SELECT CHOICE -->

    <!-- TEACHER SELECTION LIST -->
    <SelectList
      v-if="form.selectAdult"
      select-placeholder="Adult visitor front desk staff"
      select-type="frontdesk"
      :reduce="(item) => item.value.id"
      counter-icon="ri-group-line"
      info-text="Specify teachers/staff to be allowed to check-in visitors."
      @update-list-data="(value) => updateData(value)"
    />
    <!-- TEACHER SELECTION LIST -->

    <!-- FILE UPLOAD -->
    <template v-else>
      <FileUpload
        :uploaded-file="form.file"
        @update:model-value="onFileUpload"
        @remove-file="onRemoveFile"
        download-example-file-path="/csv-samples/visitor_adults.csv"
        :invalid-feedback="errors.file"
      />

      <div class="info-box mt-4">
        <InfoBox>
          Specify teachers/staff to be allowed to check-in visitors.
        </InfoBox>
      </div>
    </template>
    <!-- FILE UPLOAD -->

    <!-- FORM BUTTONS -->
    <div
      class="d-flex justify-content-center align-content-center gap-4 flex-wrap"
    >
      <BaseButton type="button" @click="onCancel" rounded>Cancel</BaseButton>
      <LoadingButton type="submit" :is-loading="isLoading" solid rounded>
        Allow
      </LoadingButton>
    </div>
    <!-- FORM BUTTONS -->
  </form>
</template>

<script>
import VisitorErrorHandler from "@/v3components/VisitorErrorHandler.vue"
import CustomCheck from "@/v3components/shared/Form/CustomCheck.vue"
import SelectList from "@/v3components/shared/Form/SelectList.vue"
import FileUpload from "@/v3components/shared/Form/FileUpload.vue"
import BaseButton from "@/v3components/shared/Buttons/BaseButton.vue"
import InfoBox from "@/v3components/shared/Alerts/InfoBox.vue"
import LoadingButton from "@/v3components/shared/Buttons/LoadingButton.vue"
import visitorConstants from "@/constants/visitorConstants"
import useVuelidate from "@vuelidate/core"
import { helpers, requiredIf } from "@vuelidate/validators"
import { computed, onMounted, reactive, ref } from "vue"
import { useStore } from "vuex"

export default {
  name: "VisitorAdultAssignForm",
  components: {
    SelectList,
    FileUpload,
    BaseButton,
    VisitorErrorHandler,
    LoadingButton,
    CustomCheck,
    InfoBox
  },
  emits: ["cancel"],
  setup(_props, { emit }) {
    const store = useStore()
    const form = reactive({
      selectAdult: true,
      file: null,
      adults: null
    })
    const isLoading = ref(false)
    const serverError = ref(null)

    const isUploadFileMarked = computed(() => form.selectAdult === false)

    const rules = {
      file: {
        requiredIf: helpers.withMessage(
          visitorConstants.VALIDATIONS.REQUIRED,
          requiredIf(() => isUploadFileMarked.value)
        )
      },
      adults: {
        requiredIf: helpers.withMessage(
          visitorConstants.VALIDATIONS.REQUIRED,
          requiredIf(() => !isUploadFileMarked.value)
        )
      }
    }

    const v$ = useVuelidate(rules, form)

    const errors = computed(() => {
      return {
        file: v$?.value?.file?.$error
          ? v$?.value?.file?.$errors?.map((error) => error.$message).join(", ")
          : ""
      }
    })

    const activeSchool = computed(
      () => store?.getters?.["schools/activeSchool"] || null
    )

    const updateData = (value) => {
      form.adults = value
    }

    const onFileUpload = (event) => {
      const files = event.target.files
      if (files && files.length) {
        form.file = files[0]
        event.target.value = ""
      }
    }

    const onRemoveFile = () => {
      form.file = null
    }

    const getValidationErrorMessages = (data) => {
      return (
        data.message +
        "\n\n" +
        data.errors
          .map((error) => `Row ${error.row} : ${error.message}`)
          .join("\n")
      )
    }

    const onSubmit = async () => {
      const isFormCorrect = await v$.value.$validate()
      if (!isFormCorrect) {
        return
      }

      if (isUploadFileMarked.value) {
        try {
          serverError.value = null
          isLoading.value = true
          const formData = new FormData()
          formData.append("csv_file", form.file)
          const { data } = await store.dispatch(
            "visitorSettings/uploadAdults",
            formData
          )
          if (
            data?.importResults?.filter((result) => result?.status === "error")
              ?.length > 0
          ) {
            throw new Error("User(s) not found")
          }
          if (data?.errors?.length > 0) {
            throw new Error(getValidationErrorMessages(data))
          }
          await store.dispatch("visitorSettings/getAdults")
          store.commit("search/SET_CLEAR_CACHE", true)
          emit("cancel")
        } catch (error) {
          serverError.value = error
        } finally {
          isLoading.value = false
        }
        return
      }

      const payload = {
        user_ids: form.adults
      }

      try {
        serverError.value = null
        isLoading.value = true
        const { data } = await store.dispatch(
          "visitorSettings/assignSpecificAdults",
          payload
        )
        if (
          data?.importResults?.filter((result) => result?.status === "error")
            ?.length > 0
        ) {
          throw new Error(getImportErrorMessages(data))
        }
        await store.dispatch("visitorSettings/getAdults")
        store.commit("search/SET_CLEAR_CACHE", true)
        emit("cancel")
      } catch (error) {
        serverError.value = error
      } finally {
        isLoading.value = false
      }
    }

    const onCancel = () => {
      emit("cancel")
    }

    onMounted(() => {
      store.commit("search/SET_CLEAR_CACHE", true)
    })

    return {
      v$,
      form,
      isLoading,
      serverError,
      errors,
      updateData,
      onFileUpload,
      onRemoveFile,
      onSubmit,
      onCancel
    }
  }
}
</script>
